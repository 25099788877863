<template>
      <div>
            <template>
                  <b-card>
                        <b-col sm="6">
                              <label for="">Tipo Reporte</label>
                              <v-select v-model="showReport" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                                    :options="typeReport" @input="showTypeReport(showReport)" />
                        </b-col>
                  </b-card>
            </template>

            <template v-if="reportSales">
                  <b-card>
                        <b-row>
                              <b-col sm="5">
                                    <label for="example-datepicker">Desde</label>
                                    <b-form-datepicker v-model="form.from" class="mb-1" />
                              </b-col>
                              <b-col sm="5">
                                    <label for="example-datepicker">Hasta</label>
                                    <b-form-datepicker v-model="form.to" class="mb-1" />
                              </b-col>
                              <b-col sm="2" class="mt-2">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                                          class="btn-icon rounded-circle" @click="searchReportSales()">
                                          <feather-icon icon="SearchIcon" />
                                    </b-button>
                              </b-col>
                        </b-row>
                  </b-card>
                  <b-card>
                        <b-row>
                              <b-col sm="12" class="mb-2 text-right">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success"
                                          class="btn-icon rounded-circle" @click="generateReportSales()">
                                          <feather-icon icon="DownloadIcon" />
                                    </b-button>
                              </b-col>
                              <b-col sm="12">
                                    <b-table ref="refRoleListTable" class="position-relative" :items="dataTable" responsive
                                          :fields="tableColumns" primary-key="id" :sort-by.sync="tableSettings.sortBy"
                                          show-empty empty-text="No se encontraron datos"
                                          :sort-desc.sync="tableSettings.sortDesc">

                                          <template #cell(actions)="data">
                                                <b-button variant="danger"
                                                      @click="deteteInfo('voucher/delete/' + data.item.id, 1)">
                                                      <feather-icon icon="TrashIcon" />
                                                </b-button>
                                          </template>
                                    </b-table>
                              </b-col>
                        </b-row>
                  </b-card>
            </template>

            <template v-if="reportPolicy">
                  <b-card>
                        <b-row>
                              <b-col sm="5">
                                    <label for="example-datepicker">Desde</label>
                                    <b-form-datepicker v-model="formPolicy.from" class="mb-1" />
                              </b-col>
                              <b-col sm="5">
                                    <label for="example-datepicker">Hasta</label>
                                    <b-form-datepicker v-model="formPolicy.to" class="mb-1" />
                              </b-col>
                              <b-col sm="2" class="mt-2">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success"
                                          class="btn-icon rounded-circle" @click="searchReportPolicy()">
                                          <feather-icon icon="DownloadIcon" />
                                    </b-button>
                              </b-col>
                        </b-row>
                  </b-card>
                  <b-card>
                        <b-row>
                              <b-col sm="12" class="mb-2 text-right">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success"
                                          class="btn-icon rounded-circle" @click="generateReportPolicy()">
                                          <feather-icon icon="DownloadIcon" />
                                    </b-button>
                              </b-col>
                              <b-col sm="12">
                                    <b-table ref="refRoleListTable" class="position-relative" :items="dataTablePolicy"
                                          responsive :fields="tableColumnsPolicy" primary-key="id"
                                          :sort-by.sync="tableSettings.sortBy" show-empty empty-text="No se encontraron datos"
                                          :sort-desc.sync="tableSettings.sortDesc">

                                          <template #cell(actions)="data">
                                                <b-button variant="danger"
                                                      @click="deteteInfo('voucher/delete/' + data.item.id, 2)">
                                                      <feather-icon icon="TrashIcon" />
                                                </b-button>
                                          </template>
                                    </b-table>
                              </b-col>
                        </b-row>
                  </b-card>
            </template>

            <template v-if="reportVouchersActive">
                  <b-card>
                        <b-row>
                              <b-col sm="12" class="mb-2 text-right">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success"
                                          class="btn-icon rounded-circle" @click="generateReportVouchersActive()">
                                          <feather-icon icon="DownloadIcon" />
                                    </b-button>
                              </b-col>
                              <b-col sm="12">
                                    <b-table ref="refRoleListTable" class="position-relative" :items="dataTableVouchersActive"
                                          responsive :fields="tableColumnsVouchersActive" primary-key="id"
                                          :sort-by.sync="tableSettings.sortBy" show-empty empty-text="No se encontraron datos"
                                          :sort-desc.sync="tableSettings.sortDesc">
                                          <template #cell(actions)="data">
                                                <b-button variant="danger"
                                                      @click="deteteInfo('voucher/delete/' + data.item.id, 3)">
                                                      <feather-icon icon="TrashIcon" />
                                                </b-button>
                                          </template>
                                    </b-table>
                              </b-col>
                        </b-row>
                  </b-card>
            </template>

            <template v-if="reportVouchersDate">
                  <b-card>
                        <b-row>
                              <b-col sm="5">
                                    <label for="example-datepicker">Desde</label>
                                    <b-form-datepicker v-model="formVoucherNoStatus.from" class="mb-1" />
                              </b-col>
                              <b-col sm="5">
                                    <label for="example-datepicker">Hasta</label>
                                    <b-form-datepicker v-model="formVoucherNoStatus.to" class="mb-1" />
                              </b-col>
                              <b-col sm="2" class="mt-2">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                                          class="btn-icon rounded-circle" @click="searchReportVoucherNoStatus()">
                                          <feather-icon icon="SearchIcon" />
                                    </b-button>
                              </b-col>
                        </b-row>
                  </b-card>
                  <b-card>
                        <b-row>
                              <b-col sm="12" class="mb-2 text-right">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success"
                                          @click="generateExcelVocuherSatusActive()" class="btn-icon rounded-circle">
                                          <feather-icon icon="DownloadIcon" />
                                    </b-button>
                              </b-col>
                              <b-col sm="12">
                                    <b-table ref="refRoleListTable" class="position-relative"
                                          :items="dataTableVoucherNoStatus" responsive :fields="tableColumnsVoucherNoStatus"
                                          primary-key="id" :sort-by.sync="tableSettings.sortBy" show-empty
                                          empty-text="No se encontraron datos" :sort-desc.sync="tableSettings.sortDesc">

                                          <template #cell(actions)="data">
                                                <b-button variant="danger"
                                                      @click="deteteInfo('voucher/delete/' + data.item.id, 4)">
                                                      <feather-icon icon="TrashIcon" />
                                                </b-button>
                                          </template>
                                    </b-table>
                              </b-col>
                        </b-row>
                  </b-card>
            </template>

            <template v-if="reportCotizations">
                  <b-card>
                        <b-row>
                              <b-col sm="5">
                                    <label for="example-datepicker">Desde</label>
                                    <b-form-datepicker v-model="formCotization.from" class="mb-1" />
                              </b-col>
                              <b-col sm="5">
                                    <label for="example-datepicker">Hasta</label>
                                    <b-form-datepicker v-model="formCotization.to" class="mb-1" />
                              </b-col>
                              <b-col sm="2" class="mt-2">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                                          class="btn-icon rounded-circle" @click="searchReportCotizations()">
                                          <feather-icon icon="SearchIcon" />
                                    </b-button>
                              </b-col>
                        </b-row>
                  </b-card>
                  <b-card>
                        <b-row>
                              <b-col sm="12" class="mb-2 text-right">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success"
                                          class="btn-icon rounded-circle" @click="generateReportCotizations()">
                                          <feather-icon icon="DownloadIcon" />
                                    </b-button>
                              </b-col>
                              <b-col sm="12">
                                    <b-table ref="refRoleListTable" class="position-relative" :items="dataTableCotizations"
                                          responsive :fields="tableColumnsCotizations" primary-key="id"
                                          :sort-by.sync="tableSettings.sortBy" show-empty empty-text="No se encontraron datos"
                                          :sort-desc.sync="tableSettings.sortDesc">

                                          <template #cell(actions)="data">
                                                <b-button variant="danger"
                                                      @click="deteteInfo('voucher/delete/' + data.item.id, 5)">
                                                      <feather-icon icon="TrashIcon" />
                                                </b-button>
                                          </template>
                                    </b-table>
                              </b-col>
                        </b-row>
                  </b-card>
            </template>

            <template v-if="reportVouchersVencimiento">
                  <b-card>
                        <b-row>
                              <b-col sm="12" class="mb-2 text-right">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success"
                                          class="btn-icon rounded-circle" @click="generateReportVencimiento()">
                                          <feather-icon icon="DownloadIcon" />
                                    </b-button>
                              </b-col>
                              <b-col sm="12">
                                    <b-table ref="refRoleListTablevenc" class="position-relative"
                                          :items="dataTableVouchersVencimiento" responsive :fields="tableColumnsVencimiento"
                                          primary-key="id" :sort-by.sync="tableSettings.sortBy" show-empty
                                          empty-text="No se encontraron datos" :sort-desc.sync="tableSettings.sortDesc">

                                          <template #cell(actions)="data">
                                                <b-button variant="danger"
                                                      @click="deteteInfo('voucher/delete/' + data.item.id, 6)">
                                                      <feather-icon icon="TrashIcon" />
                                                </b-button>
                                          </template>
                                    </b-table>
                              </b-col>
                        </b-row>
                  </b-card>
            </template>
      </div>
</template>
<script>
import { BCard, BRow, BCol, BFormDatepicker, BButton, BTable, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
export default {
      components: {
            BCard,
            BRow,
            BCol,
            BFormDatepicker,
            vSelect,
            BButton,
            BTable,
            BFormInput
      },
      data() {
            return {
                  formCotization: {
                        from: null,
                        to: null
                  },
                  dataTableVoucherNoStatus: [],
                  dataExcelVoucherNoStatus: [],
                  dataTableCotizations: [],
                  dataExcelCotizations: [],
                  formVoucherNoStatus: {
                        from: null,
                        to: null
                  },
                  formPolicy: {
                        from: null,
                        to: null
                  },
                  showReport: false,
                  reportSales: false,
                  reportPolicy: false,
                  reportVouchersActive: false,
                  reportVouchersVencimiento: false,
                  reportVouchersDate: false,
                  reportCotizations: false,
                  typeReport: [
                        { value: 1, title: 'Reporte Ventas' },
                        { value: 2, title: 'Reporte Váuchers En StandBy' },
                        { value: 3, title: 'Reporte Váuchers Activos' },
                        { value: 4, title: 'Reporte Váuchers Fechas' },
                        { value: 5, title: 'Reporte Cotizaciones' },
                        { value: 6, title: 'Reporte Váuchers Vencimiento' },
                  ],
                  listAgencies: [],
                  form: {
                        from: null,
                        to: null
                  },
                  dataTable: [],
                  dataTablePolicy: [],
                  dataTableVouchersActive: [],
                  dataExcelPolicy: [],
                  dataExcelVouchersActive: [],
                  dataExcelVouchersVencimiento: [],
                  dataTableVouchersVencimiento: [],
                  tableColumns: [
                        { key: 'date', label: 'Fecha Emisión' },
                        { key: 'policy', label: 'Consecutivo Váucher' },
                        { key: 'name_client', label: 'Cliente' },
                        { key: 'phone_client', label: 'Telefono Cliente' },
                        { key: 'identify_client', label: 'Cedula Cliente' },
                        { key: 'age_client', label: 'Edad Cliente' },
                        { key: 'email_client', label: 'Correo Cliente' },
                        { key: 'modality', label: 'Modalidades' },
                        { key: 'initial_date', label: 'F. Inicial' },
                        { key: 'final_date', label: 'F. Final' },
                        { key: 'days', label: 'Días Totales De Viaje' },
                        { key: 'procedency_client', label: 'Procedencia' },
                        { key: 'destiny', label: 'Destino' },
                        { key: 'value_dollars', label: 'Valor En Dolares' },
                        { key: 'total_cop', label: 'Total En Pesos' },
                        { key: 'discount', label: 'Descuento' },
                        { key: 'full_name_emisor', label: 'Nombre Emisor' },
                        { key: 'agency', label: 'Agencia' },
                        { key: 'actions', label: '' }
                  ],
                  tableColumnsCotizations: [
                        { key: 'date', label: 'Fecha Emisión' },
                        { key: 'policy', label: 'Consecutivo Váucher' },
                        { key: 'name_client', label: 'Cliente' },
                        { key: 'phone_client', label: 'Telefono Cliente' },
                        { key: 'identify_client', label: 'Cedula Cliente' },
                        { key: 'age_client', label: 'Edad Cliente' },
                        { key: 'email_client', label: 'Correo Cliente' },
                        { key: 'modality', label: 'Modalidades' },
                        { key: 'initial_date', label: 'F. Inicial' },
                        { key: 'final_date', label: 'F. Final' },
                        { key: 'days', label: 'Días Totales De Viaje' },
                        { key: 'procedency_client', label: 'Procedencia' },
                        { key: 'destiny', label: 'Destino' },
                        { key: 'value_dollars', label: 'Valor En Dolares' },
                        { key: 'total_cop', label: 'Total En Pesos' },
                        { key: 'discount', label: 'Descuento' },
                        { key: 'full_name_emisor', label: 'Nombre Emisor' },
                        { key: 'agency', label: 'Agencia' },
                        { key: 'actions', label: '' }
                  ],
                  tableColumnsPolicy: [
                        { key: 'date', label: 'Fecha Emisión' },
                        { key: 'policy', label: 'Consecutivo Váucher' },
                        { key: 'name_client', label: 'Cliente' },
                        { key: 'phone_client', label: 'Telefono Cliente' },
                        { key: 'identify_client', label: 'Cedula Cliente' },
                        { key: 'age_client', label: 'Edad Cliente' },
                        { key: 'email_client', label: 'Correo Cliente' },
                        { key: 'modality', label: 'Modalidades' },
                        { key: 'initial_date', label: 'F. Inicial' },
                        { key: 'final_date', label: 'F. Final' },
                        { key: 'days', label: 'Días Totales De Viaje' },
                        { key: 'procedency_client', label: 'Procedencia' },
                        { key: 'destiny', label: 'Destino' },
                        { key: 'value_dollars', label: 'Valor En Dolares' },
                        { key: 'total_cop', label: 'Total En Pesos' },
                        { key: 'discount', label: 'Descuento' },
                        { key: 'full_name_emisor', label: 'Nombre Emisor' },
                        { key: 'agency', label: 'Agencia' },
                        { key: 'actions', label: '' }

                  ],
                  tableColumnsVouchersActive: [
                        { key: 'date', label: 'Fecha Emisión' },
                        { key: 'policy', label: 'Consecutivo Váucher' },
                        { key: 'name_client', label: 'Cliente' },
                        { key: 'phone_client', label: 'Telefono Cliente' },
                        { key: 'identify_client', label: 'Cedula Cliente' },
                        { key: 'age_client', label: 'Edad Cliente' },
                        { key: 'email_client', label: 'Correo Cliente' },
                        { key: 'modality', label: 'Modalidades' },
                        { key: 'initial_date', label: 'F. Inicial' },
                        { key: 'final_date', label: 'F. Final' },
                        { key: 'days', label: 'Días Totales De Viaje' },
                        { key: 'procedency_client', label: 'Procedencia' },
                        { key: 'destiny', label: 'Destino' },
                        { key: 'value_dollars', label: 'Valor En Dolares' },
                        { key: 'total_cop', label: 'Total En Pesos' },
                        { key: 'discount', label: 'Descuento' },
                        { key: 'full_name_emisor', label: 'Nombre Emisor' },
                        { key: 'agency', label: 'Agencia' },
                        { key: 'actions', label: '' }
                  ],
                  tableColumnsVencimiento: [
                        { key: 'date', label: 'Fecha Emisión' },
                        { key: 'policy', label: 'Consecutivo Váucher' },
                        { key: 'name_client', label: 'Cliente' },
                        { key: 'phone_client', label: 'Telefono Cliente' },
                        { key: 'identify_client', label: 'Cedula Cliente' },
                        { key: 'age_client', label: 'Edad Cliente' },
                        { key: 'email_client', label: 'Correo Cliente' },
                        { key: 'modality', label: 'Modalidades' },
                        { key: 'initial_date', label: 'F. Inicial' },
                        { key: 'final_date', label: 'F. Final' },
                        { key: 'days', label: 'Días Totales De Viaje' },
                        { key: 'procedency_client', label: 'Procedencia' },
                        { key: 'destiny', label: 'Destino' },
                        { key: 'value_dollars', label: 'Valor En Dolares' },
                        { key: 'total_cop', label: 'Total En Pesos' },
                        { key: 'discount', label: 'Descuento' },
                        { key: 'full_name_emisor', label: 'Nombre Emisor' },
                        { key: 'agency', label: 'Agencia' },
                        { key: 'days_rest', label: 'Dias restantes' },
                        { key: 'actions', label: '' }
                  ],
                  tableColumnsVoucherNoStatus: [
                        { key: 'date', label: 'Fecha Emisión' },
                        { key: 'policy', label: 'Consecutivo Váucher' },
                        { key: 'name_client', label: 'Cliente' },
                        { key: 'phone_client', label: 'Telefono Cliente' },
                        { key: 'identify_client', label: 'Cedula Cliente' },
                        { key: 'age_client', label: 'Edad Cliente' },
                        { key: 'email_client', label: 'Correo Cliente' },
                        { key: 'modality', label: 'Modalidades' },
                        { key: 'initial_date', label: 'F. Inicial' },
                        { key: 'final_date', label: 'F. Final' },
                        { key: 'days', label: 'Días Totales De Viaje' },
                        { key: 'procedency_client', label: 'Procedencia' },
                        { key: 'destiny', label: 'Destino' },
                        { key: 'value_dollars', label: 'Valor En Dolares' },
                        { key: 'total_cop', label: 'Total En Pesos' },
                        { key: 'discount', label: 'Descuento' },
                        { key: 'full_name_emisor', label: 'Nombre Emisor' },
                        { key: 'agency', label: 'Agencia' },
                        { key: 'actions', label: '' }
                  ],
                  tableSettings: {
                        filter: this.$route.params && this.$route.params.id,
                        searchQuery: '',
                        input: '',
                        typeSearch: '',
                        perPage: 25,
                        page: 1,
                        sortBy: 'id',
                        sortDesc: true,
                  },
            }
      },
      methods: {
            generateExcelVocuherSatusActive() {
                  this.$http.post('/reports/generate-excel-voucher-no-status', this.dataExcelVoucherNoStatus, { responseType: 'blob' })
                        .then((response) => {
                              const url = window.URL.createObjectURL(new Blob([response.data]));
                              const link = document.createElement('a');
                              link.href = url;
                              link.setAttribute('download', 'excel.xlsx');
                              document.body.appendChild(link);
                              link.click();
                              this.$swal({
                                    icon: "success",
                                    title: "Éxito",
                                    html: "Archivo descargado éxitosamente!",
                              });
                        })
                        .catch((error) => {
                              this.errors = error.response.data.errors;
                        });
            },
            generateReportVouchersActive() {
                  this.$http.post('/reports/generate-excel-vouchers-active', this.dataExcelVouchersActive, { responseType: 'blob' })
                        .then((response) => {
                              const url = window.URL.createObjectURL(new Blob([response.data]));
                              const link = document.createElement('a');
                              link.href = url;
                              link.setAttribute('download', 'vouchers-activos.xlsx');
                              document.body.appendChild(link);
                              link.click();
                              this.$swal({
                                    icon: "success",
                                    title: "Éxito",
                                    html: "Archivo descargado éxitosamente!",
                              });
                        })
                        .catch((error) => {
                              this.errors = error.response.data.errors;
                        });
            },
            searchReportPolicy() {
                  this.$http.post('/reports/search-policy', this.formPolicy)
                        .then((response) => {
                              this.dataTablePolicy = response.data.standsby
                              this.dataExcelPolicy = response.data.standsby
                              this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                          title: `Usuario creado`,
                                          icon: 'CoffeeIcon',
                                          variant: 'success',
                                    },
                              })
                        })
                        .catch(error => {
                              console.log('error')
                        })
            },
            searchReportVoucherNoStatus() {
                  this.$http.post('/reports/search-voucher-no-status', this.formVoucherNoStatus)
                        .then((response) => {
                              this.dataTableVoucherNoStatus = response.data.vouchersNoStatus
                              this.dataExcelVoucherNoStatus = response.data.vouchersNoStatus
                              this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                          title: `Usuario creado`,
                                          icon: 'CoffeeIcon',
                                          variant: 'success',
                                    },
                              })
                        })
                        .catch(error => {
                              console.log('error')
                        })
            },
            searchVouchersAtive() {
                  this.$http.post('/reports/search-vouchers-ative')
                        .then((response) => {
                              this.dataTableVouchersActive = response.data.vouchersActive
                              this.dataExcelVouchersActive = response.data.vouchersActive
                        })
                        .catch(error => {
                              console.log('error')
                        })
            },
            showTypeReport(objet) {
                  if (objet.value == 1) {
                        this.getAgencies()
                        this.reportSales = true
                  } else {
                        this.reportSales = false
                  }
                  if (objet.value == 2) {
                        this.reportPolicy = true
                  } else {
                        this.reportPolicy = false
                  }
                  if (objet.value == 3) {
                        this.searchVouchersAtive()
                        this.reportVouchersActive = true
                  } else {
                        this.reportVouchersActive = false
                  }
                  if (objet.value == 4) {
                        this.reportVouchersDate = true
                  } else {
                        this.reportVouchersDate = false
                  }
                  if (objet.value == 5) {
                        this.getAgencies()
                        this.reportCotizations = true
                  } else {
                        this.reportCotizations = false
                  }
                  if (objet.value == 6) {
                        this.searchReportVencimiento()
                        this.reportVouchersVencimiento = true

                  } else {
                        this.reportVouchersVencimiento = false
                  }
            },
            generateReportSales() {
                  this.$http.post('/reports/generate-excel-policy', this.dataExcel, { responseType: 'blob' })
                        .then((response) => {
                              const url = window.URL.createObjectURL(new Blob([response.data]));
                              const link = document.createElement('a');
                              link.href = url;
                              link.setAttribute('download', 'reporte_ventas.xlsx');
                              document.body.appendChild(link);
                              link.click();
                              this.$swal({
                                    icon: "success",
                                    title: "Éxito",
                                    html: "Archivo descargado éxitosamente!",
                              });
                        })
                        .catch((error) => {
                              this.errors = error.response.data.errors;
                        });
            },
            generateReportCotizations() {
                  this.$http.post('/reports/generate-excel-cotizations', this.dataExcelCotizations, { responseType: 'blob' })
                        .then((response) => {
                              const url = window.URL.createObjectURL(new Blob([response.data]));
                              const link = document.createElement('a');
                              link.href = url;
                              link.setAttribute('download', 'excel.xlsx');
                              document.body.appendChild(link);
                              link.click();
                              this.$swal({
                                    icon: "success",
                                    title: "Éxito",
                                    html: "Archivo descargado éxitosamente!",
                              });
                        })
                        .catch((error) => {
                              this.errors = error.response.data.errors;
                        });
            },
            generateReportVencimiento() {
                  this.$http.post('/reports/generate-excel-generateExcelVouchersVencimiento', this.dataExcelVouchersVencimiento, { responseType: 'blob' })
                        .then((response) => {
                              const url = window.URL.createObjectURL(new Blob([response.data]));
                              const link = document.createElement('a');
                              link.href = url;
                              link.setAttribute('download', 'excel.xlsx');
                              document.body.appendChild(link);
                              link.click();
                              this.$swal({
                                    icon: "success",
                                    title: "Éxito",
                                    html: "Archivo descargado éxitosamente!",
                              });
                        })
                        .catch((error) => {
                              this.errors = error.response.data.errors;
                        });
            },
            generateReportPolicy() {
                  this.$http.post('/reports/generate-excel-policy', this.dataExcelPolicy, { responseType: 'blob' })
                        .then((response) => {
                              const url = window.URL.createObjectURL(new Blob([response.data]));
                              const link = document.createElement('a');
                              link.href = url;
                              link.setAttribute('download', 'excel.xlsx');
                              document.body.appendChild(link);
                              link.click();
                              this.$swal({
                                    icon: "success",
                                    title: "Éxito",
                                    html: "Archivo descargado éxitosamente!",
                              });
                        })
                        .catch((error) => {
                              this.errors = error.response.data.errors;
                        });
            },
            searchReportVencimiento() {
                  this.$http.post('/reports/searchVouchersVencimiento')
                        .then((response) => {
                              this.dataTableVouchersVencimiento = response.data.vouchersActive
                              this.dataExcelVouchersVencimiento = response.data.vouchersActive
                        })
                        .catch(error => {
                              console.log('error')
                        })
            },
            searchReportSales() {
                  this.$http.post('/reports/sales', this.form)
                        .then((response) => {
                              this.dataTable = response.data.reports
                              this.dataExcel = response.data.reports
                              this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                          title: `Usuario creado`,
                                          icon: 'CoffeeIcon',
                                          variant: 'success',
                                    },
                              })
                        })
                        .catch(error => {
                              console.log('error')
                        })
            },
            searchReportCotizations() {
                  this.$http.post('/reports/cotizations', this.formCotization)
                        .then((response) => {
                              this.dataTableCotizations = response.data.cotizations
                              this.dataExcelCotizations = response.data.cotizations
                              this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                          title: `Usuario creado`,
                                          icon: 'CoffeeIcon',
                                          variant: 'success',
                                    },
                              })
                        })
                        .catch(error => {
                              console.log('error')
                        })
            },
            getAgencies() {
                  this.$http.get('/agencies/listSelect').then((response) => {
                        this.listAgencies = response.data.agencies
                  })
            },
            deteteInfo(url, type) {
                  this.$http.post(url).then((response) => {
                        if (type == 1) {
                              this.searchReportSales()
                        }
                        if (type == 2) {
                              this.searchReportPolicy()
                        }
                        if (type == 3) {
                              this.searchVouchersAtive()
                        }
                        if (type == 4) {
                              this.searchReportVoucherNoStatus()
                        }
                        if (type == 5) {
                              this.searchReportCotizations()
                        }
                        if (type == 6) {
                              this.searchReportVencimiento()
                        }
                  })
            },
      },
      directives: {
            Ripple,
      },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>